import axios from "axios";
import { format } from "date-fns";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

const TabInvoice = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/events`);
    setEvents(response.data);
  };

  /**
   * Start Changes Excel
   */
  const [loading, setLoading] = useState(false);
  const [suratJalanItems, setSuratJalanItems] = useState([]);
  const [itemSewa, setItemSewa] = useState([]);
  // const [eventId, setEventId] = useState("");

  const getSuratJalan = async (eventId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}/suratjalan`);
      const items = response.data.flatMap((suratJalan) => suratJalan.surat_jalan_items);
      setSuratJalanItems(items);
      return items; // Return the items
    } catch (error) {
      console.error("Failed to fetch work items", error);
    } finally {
      setLoading(false);
    } // Set loading ke false setelah fetch
  };

  const getItemSewa = async (eventId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sewa/${eventId}`);
      setItemSewa(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch work items", error);
    } finally {
      setLoading(false);
    } // Set loading ke false setelah fetch
  };

  const fetchTemplate = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/template-checklis-v6.xlsx`);
    if (!response.ok) {
      throw new Error("Failed to fetch the Excel template");
    }
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    return workbook;
  };

  const exportToExcel = async (event) => {
    try {
      console.log(event);
      setLoading(true);

      const itemsSuratJalan = await getSuratJalan(event.id);
      const itemsSewa = await getItemSewa(event.id);
      // Ambil workbook dari file template
      const workbook = await fetchTemplate();
      const worksheet = workbook.getWorksheet(1); // Ambil worksheet yang sesuai

      // Tulis data produk ke worksheet, mulai dari baris ke-18
      const border = {
        top: { style: "dotted" },
        left: { style: "thin" },
        bottom: { style: "dotted" },
        right: { style: "thin" },
      };
      const borderKiri = {
        top: { style: "dotted" },
        bottom: { style: "dotted" },
        left: { style: "thin" },
      };
      const borderKanan = {
        top: { style: "dotted" },
        bottom: { style: "dotted" },
        right: { style: "thin" },
      };

      worksheet.getCell("N4").value = ": " + format(new Date(event.eventDate), "d MMMM yyyy", { locale: id });
      worksheet.getCell("N5").value = ": ";
      worksheet.getCell("N6").value = ": " + event.orderBy;
      worksheet.getCell("N7").value = ": " + event.name;
      worksheet.getCell("N8").value = ": " + event.venue;
      worksheet.getCell("N9").value = ": " + format(new Date(event.eventDate), "d MMMM yyyy", { locale: id });
      worksheet.getCell("N10").value = ": " + format(new Date(event.loadingDate), "d MMMM yyyy", { locale: id });

      let rowIndex = 17;
      itemsSuratJalan.forEach((item, index) => {
        worksheet.getCell(`B${rowIndex}`).value = index + 1;
        worksheet.getCell(`C${rowIndex}`).value = item.name;
        worksheet.getCell(`E${rowIndex}`).value = item.quantity;
        worksheet.getCell(`G${rowIndex}`).value = item.periodTime;
        worksheet.getCell(`H${rowIndex}`).value = "Time";
        worksheet.getCell(`I${rowIndex}`).value = item.periodeDay;
        worksheet.getCell(`J${rowIndex}`).value = "Day";

        worksheet.getCell(`B${rowIndex}`).border = border;
        worksheet.getCell(`C${rowIndex}`).border = borderKiri;
        worksheet.getCell(`D${rowIndex}`).border = borderKanan;
        worksheet.getCell(`E${rowIndex}`).border = borderKiri;
        worksheet.getCell(`F${rowIndex}`).border = borderKanan;
        worksheet.getCell(`G${rowIndex}`).border = borderKiri;
        worksheet.getCell(`H${rowIndex}`).border = borderKanan;
        worksheet.getCell(`I${rowIndex}`).border = borderKiri;
        worksheet.getCell(`J${rowIndex}`).border = borderKanan;
        worksheet.getCell(`K${rowIndex}`).border = border;
        worksheet.getCell(`L${rowIndex}`).border = border;
        worksheet.getCell(`M${rowIndex}`).border = border;
        worksheet.getCell(`N${rowIndex}`).border = border;

        rowIndex++;
      });
      itemsSewa.forEach((item, index) => {
        worksheet.getCell(`B${rowIndex}`).value = suratJalanItems.length + index + 1;
        worksheet.getCell(`C${rowIndex}`).value = item.name;
        worksheet.getCell(`E${rowIndex}`).value = item.quantity;
        worksheet.getCell(`G${rowIndex}`).value = item.periodTime;
        worksheet.getCell(`H${rowIndex}`).value = "Time";
        worksheet.getCell(`I${rowIndex}`).value = item.periodeDay;
        worksheet.getCell(`J${rowIndex}`).value = "Day";

        worksheet.getCell(`B${rowIndex}`).border = border;
        worksheet.getCell(`C${rowIndex}`).border = borderKiri;
        worksheet.getCell(`D${rowIndex}`).border = borderKanan;
        worksheet.getCell(`E${rowIndex}`).border = borderKiri;
        worksheet.getCell(`F${rowIndex}`).border = borderKanan;
        worksheet.getCell(`G${rowIndex}`).border = borderKiri;
        worksheet.getCell(`H${rowIndex}`).border = borderKanan;
        worksheet.getCell(`I${rowIndex}`).border = borderKiri;
        worksheet.getCell(`J${rowIndex}`).border = borderKanan;
        worksheet.getCell(`K${rowIndex}`).border = border;
        worksheet.getCell(`L${rowIndex}`).border = border;
        worksheet.getCell(`M${rowIndex}`).border = border;
        worksheet.getCell(`N${rowIndex}`).border = border;

        rowIndex++;
      });

      worksheet.getCell(`C${rowIndex + 4}`).value = "Pembayaran Melalui Transfer Bank :";
      worksheet.getCell(`M${rowIndex + 4}`).value = "Jakarta, 21 Agustus 2023";
      worksheet.getCell(`M${rowIndex + 15}`).value = "M. NARSO";
      worksheet.getCell(`C${rowIndex + 6}`).value = "BCA 501-5388860";
      worksheet.getCell(`C${rowIndex + 7}`).value = "PT. INSAN MUDA BERKREASI";

      const row = worksheet.getRow(rowIndex);
      row.height = 23.5;

      const startCol = "B"; // Kolom mulai
      const endCol = "N"; // Kolom akhir
      const rows = [rowIndex, rowIndex + 18]; // Baris yang akan diberi border

      // Loop untuk setiap baris yang ingin diberi border
      rows.forEach((rowNumber) => {
        // Loop untuk setiap kolom dari B hingga N
        for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
          const cell = worksheet.getCell(String.fromCharCode(col) + rowNumber);

          // Menentukan border untuk setiap sel pada baris tersebut
          cell.border = {
            top: { style: "thin" },
          };
        }
      });

      // Menentukan kolom dan rentang baris
      const columns = ["B", "O"]; // Kolom B
      const startRow = rowIndex; // Baris mulai
      const endRow = rowIndex + 17; // Baris akhir

      // Menambah border untuk setiap baris pada kolom B dari baris 20 hingga 38
      for (let row = startRow; row <= endRow; row++) {
        columns.forEach((column) => {
          const cell = worksheet.getCell(`${column}${row}`);
          // Menentukan border untuk setiap sel pada kolom B dan L
          cell.border = {
            left: { style: "thin" },
          };
        });
      }

      worksheet.getCell(`L${rowIndex}`).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      };
      worksheet.getCell(`M${rowIndex}`).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`B${rowIndex}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
      };

      // Simpan workbook yang telah diperbarui ke buffer
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });

      // Download file Excel
      saveAs(blob, `invoice-${event.name}.xlsx`);
      console.log("Excel file has been generated");
    } catch (error) {
      console.error("Error while filling Excel template", error);
    } finally {
      console.log("Surat Jalan Items:", suratJalanItems);
      console.log("Item Sewa:", itemSewa);
      setLoading(false);
    }
  };
  /**
   * End Changes Excel
   */

  return (
    <div className="column mx-6">
      {events.length === 0 ? (
        <p>Tidak ada event.</p>
      ) : (
        events.map((event, index) => (
          <div className="box mx-6" key={event.id}>
            <div className="mb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <h5 className="title is-5">Invoice</h5>
              </div>
              <div className="has-text-centered">
                <h4 className="subtitle is-6 ">{event.name}</h4>
                <h4 className="subtitle is-6 mt-3">Order By {event.orderBy}</h4>
              </div>
              <button className="button is-secondary mr-2" onClick={() => exportToExcel(event)}>
                Buka
              </button>
              {/* <button className="button is-secondary mr-2" onClick={() => onChecklistClick(Event, index)}>
                  Buka
                </button> */}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TabInvoice;
