import axios from "axios";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const TabInputItem = ({ handleTabClick }) => {
  const [name, setName] = useState("");
  const [jenisBarang, setJenisBarang] = useState("item event");
  const [qtyBaik, setQtyBaik] = useState("");
  const [price1, setPrice1] = useState("");
  const [price3, setPrice3] = useState("");

  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [msg, setMsg] = useState("");

  const saveItem = async (e) => {
    e.preventDefault();

    if (!name || !jenisBarang || !qtyBaik || !price1 || !price3) {
      setMsg("Lengkapi semua field untuk menambahkan item");
      setShowErrorNotification(true);
      setTimeout(() => {
        setShowErrorNotification(false);
      }, 2500);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/items`, {
        name: name,
        jenisBarang: jenisBarang,
        qtyBaik: qtyBaik,
        qtyRusak: 0,
        qtyHilang: 0,
        price1: parseInt(unformatRupiah(price1)),
        price2: 0,
        price3: parseInt(unformatRupiah(price3)),
      });
      setName("");
      setJenisBarang("");
      setQtyBaik("");
      setPrice1("");
      setPrice3("");

      // Show notification
      setMsg("Item berhasil ditambahkan.");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2500);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    setShowErrorNotification(false);
  };

  // Fungsi untuk memformat angka menjadi format Rupiah
  const formatRupiah = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Do not show decimal places
      maximumFractionDigits: 0, // Do not show decimal places
    }).format(amount);
  };

  // Fungsi untuk menghapus format Rupiah sebelum dikirim ke backend
  const unformatRupiah = (number) => {
    return number.replace(/[^,\d]/g, ""); // Hanya menyisakan angka
  };

  // Fungsi untuk menangani perubahan input dan langsung memformat ke Rupiah
  const handlePriceChange1 = (e) => {
    const value = e.target.value;
    setPrice1(formatRupiah(unformatRupiah(value))); // Set dengan format Rupiah
  };

  const handlePriceChange3 = (e) => {
    const value = e.target.value;
    setPrice3(formatRupiah(unformatRupiah(value))); // Set dengan format Rupiah
  };

  return (
    <div>
      <br />
      <h4 className="title is-4 has-text-centered mb-0">Input Alat Kerja dan Alat Event Baru</h4>
      {showNotification && (
        <div className="notification-container">
          <div className="notification is-info is-light">
            <button className="delete" onClick={handleCloseNotification}></button>
            {msg}
          </div>
        </div>
      )}
      {showErrorNotification && (
        <div className="notification-container">
          <div className="notification is-danger is-light">
            <button className="delete" onClick={handleCloseNotification}></button>
            {msg}
          </div>
        </div>
      )}

      <div className="card is-shadowless has-color-grey ml-6 mr-6">
        <div className="card-content has-background-dark pt-0">
          <div className="content">
            {/* Outer form to handle checklist saving */}
            <form onSubmit={saveItem}>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama Barang</label>
                <div className="control">
                  <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Masukkan Nama Barang" />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-grey-light">Jenis</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select value={jenisBarang} onChange={(e) => setJenisBarang(e.target.value)}>
                      <option value="item event">Alat Event</option>
                      <option value="item kerja">Alat Kerja</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Quantity</label>
                <div className="control">
                  <input type="number" className="input" value={qtyBaik} onChange={(e) => setQtyBaik(e.target.value)} placeholder="Masukkan jumlah quantity" min="0" />
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Harga Terendah</label>
                <div className="control">
                  <input type="text" className="input" value={price1} onChange={handlePriceChange1} placeholder="Masukkan harga terendah" />
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Harga Tertinggi</label>
                <div className="control">
                  <input type="text" className="input" value={price3} onChange={handlePriceChange3} placeholder="Masukkan harga tertinggi" />
                </div>
              </div>

              <div class="columns  mt-5">
                <div class="column is-one-fifth"></div>
                <div class="column">
                  <button type="submit" className="button is-success is-fullwidth disabled={loading}">
                    Simpan
                  </button>
                </div>
                <div class="column is-one-fifth"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabInputItem;
