import React, { useState } from "react";
import "../custom.css";
import TabInputItem from "./admin-content/TabInputItem";
import TabInputSewa from "./admin-content/TabInputSewa";
import TabInvoice from "./admin-content/TabInvoice";
import TabItem from "./admin-content/TabItem";

const AdminContent = () => {
  const [activeTab, setActiveTab] = useState("item");
  const [loading, setLoading] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Change the active tab when clicked
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "item":
        return <TabItem />;
      case "inputitem":
        // return <TabSuratJalan checklist={checklist} />;
        return <TabInputItem />;
      case "inputsewa":
        // return <TabChecklist checklist={checklist} onChecklistClick={handleChecklistClick} />;
        return <TabInputSewa />;
      case "invoice":
        return <TabInvoice />;
      default:
        return <p>Pilih sebuah tab.</p>;
    }
  };

  return (
    <div>
      <h3 className="title is-3 my-3">Admin</h3>

      <div className="tabs is-medium is-fullwidth custom-tabs">
        <ul>
          <li className={activeTab === "item" ? "is-active" : ""} onClick={() => handleTabClick("item")}>
            <a>List Item</a>
          </li>
          <li className={activeTab === "inputitem" ? "is-active" : ""} onClick={() => handleTabClick("inputitem")}>
            <a>Input Item Baru</a>
          </li>
          <li className={activeTab === "inputsewa" ? "is-active" : ""} onClick={() => handleTabClick("inputsewa")}>
            <a>Input Item Sewa</a>
          </li>
          <li className={activeTab === "invoice" ? "is-active" : ""} onClick={() => handleTabClick("invoice")}>
            <a>Invoice</a>
          </li>
        </ul>
      </div>

      {loading ? <p>Loading...</p> : renderActiveTab()}
    </div>
  );
};

export default AdminContent;
