import axios from "axios";
import id from "date-fns/locale/id"; // Import locale bahasa Indonesia
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoAdd } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import "../../custom.css";

registerLocale("id", id); // Daftarkan locale Indonesia

const AddSuratJalanForm = ({ handleTabClick }) => {
  const { eventId } = useParams();
  // Checklist
  const [date, setDate] = useState(new Date());
  const [jobNo, setJobNo] = useState("");
  const [nameOranggudang, setNameOranggudang] = useState("");
  const [namePic, setNamePic] = useState("");
  const [namePengirim, setNamePengirim] = useState("");
  const [namePenerima, setNamePenerima] = useState("");
  const [vehicle, setVehicle] = useState("");

  // ItemChecklist
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [periodTime, setPeriodTime] = useState("");
  const [periodeDay, setPeriodeDay] = useState("");

  const [msg, setMsg] = useState("");
  const [msgItem, setMsgItem] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checklistItems, setChecklistItems] = useState([]);
  const [suratJalanItems, setSuratJalanItems] = useState([]);
  // const [isSaved, setIsSaved] = useState(false);

  // Fungsi untuk membuka dan menutup modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    console.log("Checklist items updated:", suratJalanItems);
  }, [suratJalanItems]);

  // Fungsi untuk menambah item ke dalam checklist
  const AddItemToSuratJalan = (e) => {
    e.preventDefault();

    if (!name || !quantity || !periodTime || !periodeDay) {
      setMsgItem("Lengkapi semua field untuk menambahkan item");
      return;
    }
    const newItem = {
      name,
      quantity,
      periodTime,
      periodeDay,
    };

    // Tambahkan item ke dalam array suratJalanItems
    setSuratJalanItems((prevItems) => [...prevItems, newItem]);

    // Reset form input item
    setName("");
    setQuantity("");
    setPeriodTime("");
    setPeriodeDay("");
    setMsgItem("");
    closeModal();
  };

  // Fungsi untuk menyimpan checklist dan item-itemnya
  const saveSuratJalanAndItems = async (e) => {
    e.preventDefault();
    try {
      if (!date || !jobNo || !namePengirim || !namePenerima || !vehicle) {
        setMsg("Lengkapi semua field untuk membuat surat jalan");
        return;
      }
      if (suratJalanItems.length === 0) {
        setMsg("Tambahkan setidaknya satu item ke dalam surat jalan");
        return;
      }
      // Simpan checklist terlebih dahulu
      const suratJalanResponse = await axios.post(`${process.env.REACT_APP_API_URL}/events/${eventId}/suratjalan`, {
        date: date,
        jobNo: jobNo,
        namePengirim: namePengirim,
        namePenerima: namePenerima,
        vehicle: vehicle,
      });

      const suratJalanId = suratJalanResponse.data.suratJalan.id; // Ambil suratJalanId dari respons server

      // Simpan setiap item ke dalam checklist
      for (let item of suratJalanItems) {
        await axios.post(`${process.env.REACT_APP_API_URL}/events/${eventId}/suratjalan/${suratJalanId}/items`, {
          name: item.name,
          quantity: item.quantity,
          periodTime: item.periodTime,
          periodeDay: item.periodeDay,
        });
      }
      handleTabClick("suratjalan");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setQuantity(value); // Update state hanya jika input tidak melebihi maxQuantity
    }
  };

  return (
    <div>
      <br />
      <h4 className="title is-4 has-text-centered mb-0">Buat Surat Jalan</h4>

      <div className="card is-shadowless has-color-grey ml-6 mr-6">
        <div className="card-content has-background-dark pt-0">
          <div className="content">
            {/* Outer form to handle checklist saving */}
            <form onSubmit={saveSuratJalanAndItems}>
              <p className="mt-2 has-text-centered has-text-danger ">{msg}</p>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Job No</label>
                <div className="control">
                  <input type="text" className="input" value={jobNo} onChange={(e) => setJobNo(e.target.value)} placeholder="Job No" />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-grey-light">Tanggal Surat Jalan</label>
                <div className="control">
                  <DatePicker selected={date} onChange={(date) => setDate(date)} className="input" locale="id" placeholderText="Pilih tanggal" dateFormat="d MMMM yyyy" />
                </div>
              </div>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Vehicle</label>
                <div className="control">
                  <input type="text" className="input" value={vehicle} onChange={(e) => setVehicle(e.target.value)} placeholder="Vehicle" />
                </div>
              </div>
              <h4 className="subtitle is-4 has-text-white-ter mb-2 mt-5">Pengirim</h4>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={namePengirim} onChange={(e) => setNamePengirim(e.target.value)} placeholder="Name" />
                </div>
              </div>
              <h4 className="subtitle is-4 has-text-white-ter mb-2 mt-5">Penerima</h4>
              <div className="field">
                <label className="label is-size-6 has-text-grey-light">Nama</label>
                <div className="control">
                  <input type="text" className="input" value={namePenerima} onChange={(e) => setNamePenerima(e.target.value)} placeholder="Name" />
                </div>
              </div>

              <h4 className="subtitle is-4 has-text-white-ter mb-0">Item</h4>
              <div className="columns">
                <div className="column has-text-right pt-0 pb-0">
                  <button className="button mt-0 has-text-right is-small is-info" type="button" onClick={openModal}>
                    <IoAdd />
                    Tambah Item
                  </button>
                  {isModalOpen && (
                    <div className="modal is-active">
                      <div className="modal-background" onClick={closeModal}></div>
                      <div className="modal-content">
                        <div className="box is-shadowless">
                          <h4 className="title is-4 mt-3 has-text-centered">Tambah Item</h4>
                          {/* Not using form inside modal */}
                          <div>
                            <p className="has-text-centered has-text-danger has-shadow">{msgItem}</p>
                            <div className="field">
                              <label className="label has-text-left">Name</label>
                              <div className="control">
                                <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Product Name" />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">QTY</label>
                              <div className="control">
                                <input type="text" className="input" value={quantity} onChange={handleQuantityChange} placeholder="Quantity" />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">Period Time</label>
                              <div className="control">
                                <input type="text" className="input" value={periodTime} onChange={(e) => setPeriodTime(e.target.value)} placeholder="Period Time" />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label has-text-left">Period Day</label>
                              <div className="control">
                                <input type="text" className="input" value={periodeDay} onChange={(e) => setPeriodeDay(e.target.value)} placeholder="Period Day" />
                              </div>
                            </div>
                            <div className="field">
                              <div className="control">
                                <button className="mt-5 button is-success is-fullwidth is-rounded" onClick={AddItemToSuratJalan}>
                                  Simpan
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                    </div>
                  )}
                </div>
              </div>

              <table className="table is-striped is-fullwidth has-text-grey-lighter">
                <thead>
                  <tr className="">
                    <th>No</th>
                    <th>Product Name</th>
                    <th>QTY</th>
                    <th>Time</th>
                    <th>Day</th>
                  </tr>
                </thead>
                <tbody>
                  {suratJalanItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.periodTime}</td>
                      <td>{item.periodeDay}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <br />
              <div className="columns mt-5">
                <div className="column">
                  <div class="columns">
                    <div class="column is-half"></div>
                    <div class="column">
                      <button type="button" className="button is-rounded is-fullwidth">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div class="columns">
                    <div class="column is-half">
                      <button type="submit" className="button is-rounded is-fullwidth disabled={loading}">
                        Simpan
                      </button>
                    </div>
                    <div class="column"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSuratJalanForm;
