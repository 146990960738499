import axios from "axios";
import React, { useEffect, useState } from "react";
import "../custom.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("event"); // default tab 'event'
  const [loading, setLoading] = useState(false); // State untuk loading

  useEffect(() => {
    setLoading(true); // Set loading to true saat memulai fetch
    if (activeTab === "event") {
      getAlatEvent();
    } else {
      getAlatKerja();
    }
  }, [activeTab]); // Re-run the effect when activeTab changes

  const getAlatEvent = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items?jenisBarang=item%20event`);
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch event items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const getAlatKerja = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items?jenisBarang=item%20kerja`);
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch work items", error);
    }
    setLoading(false); // Set loading ke false setelah fetch
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Change the active tab when clicked
  };

  return (
    <div>
      <h3 className="title is-3 mt-3">Dashboard Barang Meet Production</h3>

      {/* Tabs */}
      <div className="tabs is-medium is-fullwidth custom-tabs">
        {" "}
        {/* Mengganti class menjadi className */}
        <ul>
          <li className={activeTab === "event" ? "is-active" : ""} onClick={() => handleTabClick("event")}>
            <a>Item Event</a>
          </li>
          <li className={activeTab === "kerja" ? "is-active" : ""} onClick={() => handleTabClick("kerja")}>
            <a>Item Kerja</a>
          </li>
        </ul>
      </div>

      {/* Loading state handling */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="table is-striped is-fullwidth mr-5">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Item</th>
              <th>Jenis</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.uuid}>
                <td>{index + 1}</td>
                <td>{product.name}</td>
                <td>{product.jenisBarang}</td>
                <td>{product.qtyBaik}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProductList;
