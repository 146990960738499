import React, { useState } from "react";

const TabMasterClient = ({ suratJalanItems, handleMasterInternal, handleBackMaster, itemSewa }) => {
  const [loading, setLoading] = useState(false);
  if (!suratJalanItems) {
    return <p>Loading surat jalan items...</p>;
  }

  return (
    <div className="div ml-5 mr-5">
      <div class="columns mb-5 mt-5">
        <div class="column is-one-third ml-3">
          <button className="button is-secondary mr-2 is-text" onClick={() => handleBackMaster()}>
            Kembali
          </button>
        </div>
        <div class="column has-text-centered">
          <h4 className="title is-4 mr-3">Master Client</h4>
        </div>
        <div class="column has-text-right mr-3">
          <button className="button is-secondary mr-2 is-text" onClick={() => handleMasterInternal()}>
            Buka Master Internal
          </button>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div> // Atau tambahkan spinner Bulma
      ) : (
        <table className="table is-striped is-fullwidth mr-6">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>No</th>
              <th className="has-text-centered">Nama Item</th>
              <th className="has-text-centered">QTY</th>
              <th colSpan="2" className="has-text-centered">
                Period
              </th>
            </tr>
          </thead>
          <tbody>
            {suratJalanItems.map((suratJalanItem, index) => (
              <tr key={suratJalanItem.id}>
                <td>{index + 1}</td>
                <td>{suratJalanItem.name}</td>
                <td className="has-text-centered">{suratJalanItem.quantity}</td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {suratJalanItem.periodTime} Time
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {suratJalanItem.periodeDay} Day
                </td>
              </tr>
            ))}
            {itemSewa.map((sewa, index) => (
              <tr key={sewa.id}>
                <td>{suratJalanItems.length + index + 1}</td>
                <td>{sewa.name}</td>
                <td className="has-text-centered">{sewa.quantity}</td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {sewa.periodTime} Time
                </td>
                <td className="has-text-centered" style={{ width: "10rem" }}>
                  {sewa.periodeDay} Day
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* {suratJalanItems.length === 0 ? <p>No items found for surat jalan.</p> : suratJalanItems.map((item) => <div key={item.id}>{item.name}</div>)} */}
    </div>
  );
};

export default TabMasterClient;
